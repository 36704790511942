import { FC, useEffect, useMemo, useState } from "react";

import { FaqQuestionDto, LocStrDto } from "src/generated/social";

import { languagePicker } from "src/shared/helpers/languagePicker";

import i18n from "src/shared/i18n/i18n";

import { BaseBox, BaseButton, BaseInnerHtml } from "src/shared/components";

import { ERequestStatus } from "src/shared/store/types";

import styles from "./faq-page.module.scss";
import { NewPageWrapper } from "../../shared/components/new-page-wrapper/new-page-wrapper";
import { NewBaseAccordion } from "../../shared/components/new-base-accordion/new-base-accordion";
import classNames from "classnames";
import { useUnit } from "effector-react";
import {
  $faqItems,
  $faqStatusGet,
  cleanFaq,
  fetchFaq,
} from "../../entities/public/faq/model/faq";
import { useTranslation } from "react-i18next";

const FaqQuestions: FC<{ faq: FaqQuestionDto[] }> = ({ faq }) => {
  const language: keyof LocStrDto = i18n.language as keyof LocStrDto;

  const notEmptyQuestions = useMemo(
    () =>
      faq.filter(
        ({ question, answer }) => !!(answer[language] && question[language]),
      ),
    [faq, language],
  );

  const [openedId, setOpenedId] = useState<string>();

  return (
    <>
      {notEmptyQuestions.map(({ question, answer, id }, index) => (
        <NewBaseAccordion
          key={index}
          title={languagePicker(question, i18n.language)}
          onClick={() => setOpenedId(id === openedId ? "" : id)}
          open={id === openedId}
        >
          <BaseInnerHtml
            className="base-accordion__description"
            description={languagePicker(answer, i18n.language)}
          />
        </NewBaseAccordion>
      ))}
    </>
  );
};

export const FaqPage: FC = () => {
  const faq = useUnit($faqItems);
  const isLoading = useUnit($faqStatusGet) === ERequestStatus.LOADING;
  const { t } = useTranslation();

  useEffect(() => {
    fetchFaq();

    return () => {
      cleanFaq();
    };
  }, []);

  return (
    <>
      <NewPageWrapper emptyPanel isLoadingPage={isLoading} noContainer>
        <BaseBox className={styles.box} noShadow noPadding>
          <h2 className={styles.pageTitle}>{t("faq.title")}</h2>
          {faq && (
            <div className={styles.faqBody}>
              <FaqQuestions faq={faq} />
            </div>
          )}
        </BaseBox>

        <BaseBox
          className={classNames(styles.box, styles.policyBlock)}
          noShadow
          noPadding
        >
          <div className={styles.policy}>
            <h2 className={styles.policyTitle}>{t("faq.policy.title")}</h2>
            <BaseButton primary to="/policy">
              {t("faq.policy.view")}
            </BaseButton>
          </div>
        </BaseBox>
      </NewPageWrapper>
    </>
  );
};
