import React, {
  FC,
  FormEvent,
  Fragment,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useUnit } from "effector-react";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { reflect } from "@effector/reflect";

import {
  AvatarUpload,
  BaseButton,
  CaptchaPopup,
  EDesiredStudyPlace,
  EmailVerifyPopup,
  ResumeUpload,
  TextWithIcon,
  UserForm,
} from "src/shared/components";

import useInput from "src/shared/hooks/useInput";

import useClient from "src/shared/hooks/useClient";

import { useErrorScroll } from "src/shared/hooks/useErrorScroll";

import {
  getRequestKeycloakUserData,
  getUserFormData,
  toCloneObject,
} from "src/shared/helpers";

import { UserTypeEnum } from "src/shared/models";

import {
  requestGetProfileInfo,
  selectProfileInfoStatusGetProfile,
  selectProfileInfoStatusRepeatVerifiedEmail,
  selectProfileInfoStatusUpdateProfile,
} from "src/shared/store/ducks/profile-info";

import {
  cleanOptionsContactAvatar,
  cleanOptionsResume,
  requestOptionsResume,
  requestOptionsUserAvatar,
  selectCdnOptionsResume,
  selectCdnOptionsResumeStatus,
  selectCdnOptionsUserAvatar,
  selectCdnOptionsUserAvatarStatus,
  selectCdnResumeStatus,
  selectCdnUserAvatarStatus,
} from "src/shared/store/ducks/cdn";

import { ERequestStatus } from "src/shared/store/types";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import { $countries } from "src/entities/public/countries";

import {
  Country,
  District,
  Region,
  School,
  userFormModel,
} from "src/features/public/user-form";

import { ResetPassword } from "src/features/public/account";

import { appAccessModel } from "src/features/public/app-access";

import "./info-page.scss";
import { UserRights } from "../../../features/hr/account/ui/user-rights/user-rights";
import { NewPageWrapper } from "../../../shared/components/new-page-wrapper/new-page-wrapper";
import { useDeepEqual } from "../../../shared/hooks/useDeepEqual";
import { DraftPopup } from "../../../shared/components/popups/draft-popup/draft-popup";
import { $isDraft, setIsDraft } from "./lib/draft";
import {
  $profile,
  $socialPlayerInfo,
  postProfile,
} from "../../../features/public/app-access/model";
import { UserReprAcc } from "../../../generated/keycloak";
import {
  additionalOptions,
  commonOptions,
  entrantOptions,
  hrOptions,
  playerOptions,
} from "../../../shared/helpers/formValidate";
import useProfileData from "../../../shared/hooks/useProfileData";
import { StatusCompanyEnum } from "../../../generated/social";
import { RelativesBlock } from "../relatives/relatives-block/relatives-block";
import { $isNoAccess } from "../../../entities/public/relatives/get-profile-relative-users/get-profile-relative-users";
import { $keycloak } from "../../../entities/public/keycloak/model";

interface Props {
  countries: Country[];
  regions: Region[];
  districts: District[];
  schools: School[];
  isSomeDictionaryLoading: boolean;
}

const requiredFields: { [key: string]: boolean } = {
  firstName: true,
  lastName: true,
  email: true,
  phoneNumber: true,
  country: true,
  gender: true,
  region: true,
  district: true,
  locality: true,
  school: true,
  birthdate: true,
  supervisedUnit: true,
  studyWorkPlace: true,
  workPhoneNumber: true,
  avatarUrl: true,
  resumeFileName: true,
  desiredStudyPlace: true,
  desiredSpecialty: true,
};

const { openPopup, closePopup } = popupModel;

export const View: FC<Props> = memo(
  ({ regions, districts, schools, isSomeDictionaryLoading }): ReactElement => {
    const { validateValue } = useInput();
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});
    const { isErrorScroll, setIsErrorScroll, scrollToErrorField } =
      useErrorScroll();
    const [firstTry, setFirstTry] = useState<boolean>(true);
    const { t, i18n } = useTranslation();
    const keycloak = useUnit($keycloak);
    const dispatch = useDispatch();
    const avatarOptions = useSelector(selectCdnOptionsUserAvatar);
    const loadingAvatarOptions = useSelector(selectCdnOptionsUserAvatarStatus);
    const loadingAvatar = useSelector(selectCdnUserAvatarStatus);
    const loadingResume = useSelector(selectCdnResumeStatus);
    const resumeOptions = useSelector(selectCdnOptionsResume);
    const loadingResumeOptions = useSelector(selectCdnOptionsResumeStatus);
    const profileInfo = useUnit($profile);
    const loadingGetProfile = useSelector(selectProfileInfoStatusGetProfile);
    const loadingRepeatVerifiedEmail = useSelector(
      selectProfileInfoStatusRepeatVerifiedEmail,
    );

    const socialPlayerInfo = useUnit($socialPlayerInfo);

    const isNoAccess = useUnit($isNoAccess);

    const showRelativeBlock =
      socialPlayerInfo?.statusCompany === StatusCompanyEnum.Student ||
      socialPlayerInfo?.statusCompany ===
        StatusCompanyEnum.CandidateRelatives ||
      socialPlayerInfo?.statusCompany === StatusCompanyEnum.Relative ||
      !isNoAccess;

    const isFullProfileWithoutEmailVerified = useUnit(
      appAccessModel.$isFullProfileWithoutEmailVerified,
    );
    const { isPlayerClientId, isHrClientId, isAdminClientId } = useClient();

    const [recaptchaToken, setRecaptchaToken] = useState<string>();

    const [recaptchaTokenForm, setRecaptchaTokenForm] = useState<string>();

    const deepEqual = useDeepEqual();

    const userId: string = keycloak?.subject || "";

    const isDraft = useUnit($isDraft);

    const confirmedStudent =
      socialPlayerInfo?.statusCompany === StatusCompanyEnum.Student;

    const confirmedEmployee =
      socialPlayerInfo?.statusCompany === StatusCompanyEnum.Employee;

    const cropDescription: string[] = [
      t("popup.editContacts.crop.contacts.description.willAsAvatar"),
    ];

    const someString = `${"ecaps.agubala.rh.ved//:spt"}${"th"}`;
    const isShowCaptcha =
      process.env.REACT_APP_DOMAIN !== someString.split("").reverse().join("");

    const isLoadingAvatarOptions: boolean =
      loadingAvatarOptions === ERequestStatus.LOADING;
    const isLoadingResumeOptions: boolean =
      loadingResumeOptions === ERequestStatus.LOADING;
    const isLoadingGetProfile: boolean =
      loadingGetProfile === ERequestStatus.LOADING;
    const isLoadingUpdateProfile: boolean = useUnit(
      appAccessModel.$isProfileLoading,
    );
    const isLoadedGetProfile: boolean =
      loadingGetProfile === ERequestStatus.LOADED;
    const isLoadingEmailVerified: boolean =
      loadingRepeatVerifiedEmail === ERequestStatus.LOADING;
    const isLoadingAvatar: boolean = loadingAvatar === ERequestStatus.LOADING;
    const isLoadingResume: boolean = loadingResume === ERequestStatus.LOADING;

    const isPageLoading: boolean =
      isLoadingResumeOptions || isLoadingAvatarOptions || isLoadingGetProfile;

    const isProfileContentReady: boolean =
      isLoadedGetProfile && !!profileInfo && !firstTry;

    const isSomeLoading: boolean =
      isLoadingUpdateProfile ||
      isLoadingEmailVerified ||
      isLoadingAvatar ||
      isLoadingResume ||
      isSomeDictionaryLoading;

    const isCaptchaVerified = recaptchaToken || recaptchaTokenForm;

    const {
      formData,
      uploadFiles,
      onSaveAvatarHandler,
      onSaveResumeHandler,
      onToggleFileLoadedHandler,
      setUploadFiles,
      setFormData,
      onSaveStatusCompany,
      onChangeAvatarHandler,
      onChangeResumeHandler,
    } = useProfileData({ userId: userId, socialPlayerInfo: socialPlayerInfo! });

    useEffect(() => {
      if (
        profileInfo &&
        deepEqual(getUserFormData(profileInfo, socialPlayerInfo), formData)
      ) {
        setIsDraft(true);
      }

      return () => {
        setIsDraft(false);
      };
    }, [deepEqual, formData, profileInfo, socialPlayerInfo]);

    useEffect(() => {
      dispatch(
        requestGetProfileInfo({
          url: `${process.env.REACT_APP_KC_URL}/realms/${process.env.REACT_APP_KC_REALM}/accountEx`,
          token: localStorage.getItem("alabuga-token"),
        }),
      );

      dispatch(requestOptionsUserAvatar());

      if (isPlayerClientId) {
        dispatch(requestOptionsResume());
      }
    }, [dispatch, isPlayerClientId]);

    useEffect(() => {
      if (recaptchaToken) {
        setRecaptchaToken("");
      }

      return () => {
        dispatch(cleanOptionsContactAvatar());

        if (isPlayerClientId) {
          dispatch(cleanOptionsResume());
        }
      };
    }, [dispatch, isPlayerClientId]);

    useEffect(() => {
      if (profileInfo && firstTry) {
        setFirstTry(false);
        setFormData(getUserFormData(profileInfo, socialPlayerInfo));
      }
    }, [firstTry, profileInfo, setFormData, socialPlayerInfo]);

    useEffect(() => {
      const isEmptyLocalFormErrors = !Object.keys(formErrors).length;

      if (!isEmptyLocalFormErrors && isErrorScroll) {
        scrollToErrorField();
      }
    }, [formErrors, isErrorScroll, scrollToErrorField]);

    const getValidationOptions = useCallback(() => {
      let options = { ...commonOptions(formData) };
      const isAnEntrant: boolean =
        formData.desiredStudyPlace === EDesiredStudyPlace.KFU ||
        formData.desiredStudyPlace === EDesiredStudyPlace.ALABUGA_POLYTECH;

      if (isHrClientId) {
        options = {
          ...options,
          ...additionalOptions(formData, regions, districts),
          ...hrOptions(formData),
        };
      } else if (isPlayerClientId) {
        options = {
          ...options,
          ...additionalOptions(formData, regions, districts),
          ...playerOptions(formData, schools),
        };
      }

      if (isAnEntrant && !isAdminClientId && !isHrClientId) {
        options = {
          ...options,
          ...playerOptions(formData, schools),
          ...entrantOptions(formData),
        };
      }

      return options;
    }, [
      formData,
      isAdminClientId,
      isHrClientId,
      isPlayerClientId,
      regions,
      districts,
      schools,
    ]);

    const onSubmit = () => {
      if (!profileInfo || isSomeLoading) return;

      const localFormErrors = validateValue(getValidationOptions());
      const isEmptyLocalFormErrors = !Object.keys(localFormErrors).length;

      setIsErrorScroll(true);
      setFormErrors(localFormErrors);

      if (!isEmptyLocalFormErrors) {
        openPopup({
          name: EPopupName.BASE_MESSAGE_POPUP,
          message: {
            text: t("popup.baseMessage.message.fillAllFields"),
            isError: true,
          },
        });
        return;
      }

      onSaveAvatarHandler(profileInfo);
      onSaveResumeHandler(profileInfo);
      onSaveStatusCompany();
    };

    const onSubmitForm = (e: FormEvent) => {
      e.preventDefault();

      onSubmit();
    };

    const clientIdToModal = isHrClientId ? "hr" : "admin";

    useEffect(() => {
      const isFilesLoaded: boolean =
        uploadFiles.avatar.isLoaded && uploadFiles.resume.isLoaded;

      if (!isFilesLoaded) return;

      if (!isCaptchaVerified && isShowCaptcha) {
        openPopup({ name: EPopupName.CAPTCHA });
        return;
      }

      onToggleFileLoadedHandler("avatar");
      onToggleFileLoadedHandler("resume");

      let user: UserReprAcc = {
        ...(profileInfo as UserReprAcc),
        ...getRequestKeycloakUserData(formData),
      };

      if (user.attributes) {
        user.attributes.phoneNumber = [
          profileInfo?.attributes?.phoneNumber?.[0] || "",
        ];
        user.attributes.locale = [i18n.language];
      }

      delete user.emailVerified;

      postProfile(user);

      setUploadFiles((prev) => {
        const localPrev = toCloneObject(prev);

        if (localPrev) {
          localPrev.resume.file = undefined;
          localPrev.avatar.file = undefined;
        }

        return localPrev;
      });
    }, [
      dispatch,
      t,
      uploadFiles,
      i18n.language,
      formData,
      isHrClientId,
      keycloak?.clientId,
      profileInfo,
      isCaptchaVerified,
    ]);

    useEffect(() => {
      if (!recaptchaToken) return;

      closePopup({ name: EPopupName.CAPTCHA });
    }, [recaptchaToken]);

    const userType = () => {
      if (isHrClientId) {
        return UserTypeEnum.HR;
      }

      if (isPlayerClientId) {
        return UserTypeEnum.PLAYER;
      }

      if (isAdminClientId) {
        return UserTypeEnum.ADMIN;
      }

      return "";
    };

    const isRepeatEmailVerified =
      !!isFullProfileWithoutEmailVerified && !profileInfo?.emailVerified;

    const shouldDisable =
      isSomeLoading || (isShowCaptcha && !recaptchaTokenForm) || !isDraft;

    return (
      <NewPageWrapper isLoadingPage={isPageLoading} emptyPanel noContainer>
        <div className="info-page">
          <div className="info-page_title">
            <h2 className="info-page_title__text">{t("profile.title")}</h2>
          </div>
          {isProfileContentReady && (
            <Fragment>
              <div className="info-page__form">
                <div className="info-page__data">
                  {avatarOptions && (
                    <AvatarUpload
                      avatarUrl={formData.avatarUrl}
                      fileSize={avatarOptions.maxSize}
                      formats={avatarOptions.contentType}
                      className="info-page__avatar"
                      onChange={onChangeAvatarHandler}
                      cropTitle={t("profile.info.title.photo")}
                      cropDescriptions={cropDescription}
                    />
                  )}

                  {/*{isPlayerClientId && (*/}
                  {/*  <>*/}
                  {/*    <BaseButton*/}
                  {/*      outline*/}
                  {/*      to="https://doc.hr.alabuga.ru"*/}
                  {/*      className="info-page__apply_papers"*/}
                  {/*    >*/}
                  {/*      <TextWithIcon*/}
                  {/*        iconSize={24}*/}
                  {/*        customWidth={"60%"}*/}
                  {/*        iconName="docs-icon"*/}
                  {/*        label={t("profile.info.title.papersApply")}*/}
                  {/*      />*/}
                  {/*    </BaseButton>*/}
                  {/*    <BaseButton*/}
                  {/*      outline*/}
                  {/*      to="https://doc.hr.alabuga.ru/ap"*/}
                  {/*      className="info-page__apply_papers"*/}
                  {/*    >*/}
                  {/*      <TextWithIcon*/}
                  {/*        iconSize={24}*/}
                  {/*        className="info-page__apply_papers__btn"*/}
                  {/*        iconName="docs-icon"*/}
                  {/*        label={t("profile.info.title.papersApplyPolytech")}*/}
                  {/*      />*/}
                  {/*    </BaseButton>*/}
                  {/*  </>*/}
                  {/*)}*/}

                  <ResetPassword
                    className="info-page__reset"
                    clientId={keycloak?.clientId || ""}
                  />

                  {!isPlayerClientId && (isHrClientId || isAdminClientId) && (
                    <UserRights
                      userId={userId}
                      clientIdToModal={clientIdToModal}
                    />
                  )}

                  {isPlayerClientId && resumeOptions && (
                    <ResumeUpload
                      resumeName={formData.resumeFileName}
                      userId={userId}
                      className="info-page__resume"
                      fileSize={resumeOptions.maxSize}
                      formats={resumeOptions.contentType}
                      onChange={onChangeResumeHandler}
                    />
                  )}
                </div>

                <div className="info-page__data info-page__data-form">
                  <UserForm
                    isReCaptchaShow={isShowCaptcha}
                    errors={formErrors}
                    setFormErrors={setFormErrors}
                    formData={formData}
                    setFormData={setFormData}
                    userType={userType()}
                    emailVerified={isRepeatEmailVerified}
                    isPhoneNumberDisabled
                    onSubmit={onSubmitForm}
                    onRepeatVerifiedEmail={
                      isRepeatEmailVerified ? onSubmit : undefined
                    }
                    isRepeatVerifiedEmailLoading={isSomeLoading}
                    confirmedStudent={confirmedStudent}
                    confirmedEmployee={confirmedEmployee}
                    requiredFields={requiredFields}
                    setRecaptchaToken={setRecaptchaTokenForm}
                  />
                  <BaseButton
                    disabled={shouldDisable}
                    onClick={onSubmitForm}
                    isLoading={isSomeLoading}
                    className="info-page__data-btn"
                    block
                    primary
                    large
                    submit
                  >
                    {t("profile.info.btn.save")}
                  </BaseButton>
                </div>
              </div>
            </Fragment>
          )}
        </div>

        <CaptchaPopup setCaptchaToken={setRecaptchaToken} />

        {profileInfo?.email && <EmailVerifyPopup email={profileInfo?.email} />}
        <DraftPopup />
        {showRelativeBlock && (
          <RelativesBlock
            isStudent={
              socialPlayerInfo?.statusCompany === StatusCompanyEnum.Student
            }
          />
        )}
      </NewPageWrapper>
    );
  },
);

export const InfoPage = reflect<Props>({
  view: View,
  bind: {
    countries: $countries,
    regions: userFormModel.$regions,
    districts: userFormModel.$districts,
    schools: userFormModel.$schools,
    isSomeDictionaryLoading: userFormModel.$isSomeDictionaryLoading,
  },
});
