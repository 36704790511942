import {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { Resume } from "./resume";
import { ConfirmPopup, ResumeDownloadBlock } from "src/shared/components";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import "./resume-upload.scss";

import {
  $resumePreview,
  getResumePreview,
  removeResumePreview,
} from "./lib/resume";
import { useUnit } from "effector-react";

interface Props {
  userId: string;
  resumeName: string;
  formats: string[];
  fileSize: number;
  className?: string;
  onChange: (file: File | undefined, resumeName: string) => void;
}

const checkFileSize = (file: File, maxSize: number): boolean => {
  return maxSize > file?.size;
};

const { openPopup, closePopup } = popupModel;

export const ResumeUpload: FC<Props> = ({
  formats,
  fileSize,
  className,
  onChange,
  resumeName,
  userId,
}): ReactElement => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputAcceptFormats: string = formats.join(", ");
  const localFormats = formats
    .map((item) => item.replace("application/", ""))
    .join(", ");
  const localFileSize: number = Number((fileSize / (1024 * 1024)).toFixed(2));
  const [localResumeFile, setLocalResumeFile] = useState<string>();

  const resumePreviewLink = useUnit($resumePreview);

  const onShowPopupHandler = (text: string) => {
    openPopup({
      name: EPopupName.BASE_MESSAGE_POPUP,
      message: { text, isError: true },
    });
  };

  const onChangeResumeHandler = (e: ChangeEvent) => {
    const file: File | undefined = (e.target as HTMLInputElement).files?.[0];

    if (file) {
      const isCorrectFormat: boolean = formats.includes(file.type);

      if (!isCorrectFormat) {
        const errorMessage: string = `${t(
          "popup.uploadPopup.formatError",
        )} ${localFormats}.`;

        onShowPopupHandler(errorMessage);

        if (fileInputRef?.current) {
          fileInputRef.current.value = "";
        }

        return;
      }

      const validateSize: boolean = checkFileSize(file, fileSize);

      if (!validateSize) {
        onShowPopupHandler(t("common.errorMessage.fileSize"));

        return;
      }

      const reader: FileReader = new FileReader();

      const errorLoad = () => {
        console.error(`File reader error: ${file.name}`);

        onShowPopupHandler(t("common.errorMessage.file.notUpload"));
      };

      const successLoad = () => {
        if (typeof reader.result === "string") {
          onChange(file, file.name);
          setLocalResumeFile(reader.result);
        }
      };

      reader.addEventListener("error", errorLoad);

      reader.addEventListener("load", successLoad);

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (resumeName) {
      getResumePreview(userId);
    }
  }, []);

  const onDeleteResumeHandler = () => {
    onChange(undefined, "");

    setLocalResumeFile(undefined);

    closePopup({ name: EPopupName.DELETE_RESUME });

    removeResumePreview();
  };

  return (
    <div className={classNames("resume-upload", className)}>
      <h4 className="resume-upload__title">{t("table.header.resume")}</h4>
      <div className="resume-upload__description">
        <div className="resume-upload__description-item">
          {`${t("common.format")} ${localFormats.toUpperCase()} ${t(
            "common.upTo",
          )} ${localFileSize} ${t("common.mbShort")}`}
          *
        </div>
      </div>
      {resumeName && (
        <>
          {
            <div className="resume-upload__preview">
              {localResumeFile ? (
                <Resume src={localResumeFile} />
              ) : (
                resumePreviewLink && (
                  <img
                    src={`${resumePreviewLink}?${new Date().getTime()}`}
                    alt="resume"
                    className="resume-upload__preview-img"
                  />
                )
              )}
              <span className="resume-upload__preview-filename">
                {resumeName}
              </span>
            </div>
          }
          <ResumeDownloadBlock
            className="resume-upload__file"
            resumeName={resumeName}
            preUploadFile={localResumeFile}
            userId={userId}
            isDeleteBtn
          />
        </>
      )}
      <label className="resume-upload__upload">
        {t("profile.info.resume.upload")}
        <input
          className="resume-upload__upload-input"
          ref={fileInputRef}
          type="file"
          accept={inputAcceptFormats}
          onChange={onChangeResumeHandler}
          hidden
        />
      </label>
      <ConfirmPopup
        popupName={EPopupName.DELETE_RESUME}
        reverse
        title={t("profile.popup.deleteResume.title")}
        description={t("profile.popup.deleteResume.description")}
        confirmBtnLabel={t("profile.info.document.btn.delete")}
        onConfirm={onDeleteResumeHandler}
      />
    </div>
  );
};
